<template>
  <div class="body-container gap-4 min-h-full">
    <!-- displays on desktop -->
    <Card p="p-8" class="hidden md:block">
      <div class="flex items-center justify-between">
        <h4 class="text-black text-lg font-semibold">Settlements</h4>
        <div class="flex items-center">
          <Card class="searchContainer w-44 mr-2" p="px-3">
            <Select
              type="filter"
              v-model="params.status"
              placeholder="All"
              :reduce="(type) => type.value"
              :options="statusOptions"
            />
          </Card>
          <DatePicker
            class="mr-4"
            v-model="range"
            is-range
            :model-config="modelConfig"
            :masks="masks"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <Card
                class="flex items-center bSettlement searchContainer w-full"
                p="px-1.5 py-1"
              >
                <button class="p-2 focus:outline-none" @click="togglePopover()">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                  >
                    <path
                      d="M11.09 2h-.272v-.25c0-.425-.354-.75-.818-.75-.464 0-.818.325-.818.75V2H4.818v-.25C4.818 1.325 4.464 1 4 1c-.464 0-.818.325-.818.75V2h-.273C1.845 2 1 2.775 1 3.75v7c0 .975.845 1.75 1.91 1.75h8.18c1.065 0 1.91-.775 1.91-1.75v-7C13 2.775 12.155 2 11.09 2Zm-1.363-.25c0-.15.11-.25.273-.25.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1ZM4 1.5c.164 0 .273.1.273.25v1c0 .15-.11.25-.273.25-.164 0-.273-.1-.273-.25v-1c0-.15.11-.25.273-.25Zm-1.09 1h.272v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h4.364v.25c0 .425.354.75.818.75.464 0 .818-.325.818-.75V2.5h.273c.764 0 1.364.55 1.364 1.25v.75H1.544v-.75c0-.7.6-1.25 1.364-1.25Zm9.544 8.25c0 .7-.6 1.25-1.363 1.25H2.909c-.764 0-1.364-.55-1.364-1.25V5h10.91v5.75Z"
                      fill="#9D9D9D"
                      stroke="#9D9D9D"
                      stroke-width=".1"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.727 7h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 8.75h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25ZM3.727 10.5h6.546c.163 0 .273-.1.273-.25s-.11-.25-.273-.25H3.727c-.163 0-.272.1-.272.25s.109.25.272.25Z"
                      fill="#9D9D9D"
                      stroke="#9D9D9D"
                      stroke-width=".1"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <p
                  v-if="inputValue.start === null"
                  @click="togglePopover()"
                  class="text-grey"
                >
                  Search by Date
                </p>
                <p v-else @click="togglePopover()" class="text-sm text-grey">
                  {{ inputValue.start }} - {{ inputValue.end }}
                </p>
              </Card>
            </template>
          </DatePicker>
          <Button text="Search" p="p-2" @click="handleSearch" />
        </div>
      </div>
      <!-- <hr class="bg-lightPurple my-2 border-lightPurple" /> -->
      <Settlement
        :loading="loading"
        :getSettlements="getSettlements"
        :paginateLoading="paginateLoading"
        :handleMore="handleMore"
        @singleSettlement="handleSingleSettlement"
        @paginate="handlePagination"
      />
    </Card>
    <!-- <Bills class="hidden md:block" /> -->
    <!-- for mobile display -->
    <MobileView
      class="md:hidden"
      @singleSettlement="handleSingleSettlement"
      @filter="
        params = $event;
        getMerchantSettlements();
      "
      @paginate="handlePagination"
      :settlementDetails="settlementDetails"
      :loading="loading"
      :paginateLoading="paginateLoading"
      :getSettlements="getSettlements"
      :handleMore="handleMore"
      :statusOptions="statusOptions"
      :params="params"
    />
    <!-- modal for single Settlement -->
    <Modal
      :display="showSettlement"
      @close="showSettlement = false"
      title="Settlement Details"
      :params="params"
    >
      <SingleSettlement :settlementDetails="settlementDetails" />
      <p
        class="cursor-pointer mt-8 text-sm"
        @click="showTransactionsBatch(settlementDetails.id)"
      >
        <span class="md:mr-2"
          >View transactions in this settlement batch >></span
        >
      </p>
    </Modal>

    <Modal
      :display="showTransactions"
      @close="showTransactions = false"
      class="scrollbar"
      title="Settlement details"
    >
      <p class="cursor-pointer my-8 text-sm" @click="goBack">
        <span class="mr-2">
          &lt;&lt; Go back to see settlement details
        </span>
      </p>

      <settlement-transactions :settlementId="settlementDetails.id" />
    </Modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Settlement from "./Settlement";
import MobileView from "./MobileView";
import { DatePicker } from "v-calendar";
import Button from "@/UI/Button";
import SingleSettlement from "./SingleSettlement";
import SettlementTransactions from "./SingleSettlement/SettlementBatch";
import Search from "@/UI/SearchInput";
import Select from "@/UI/Select";
export default {
  components: {
    Settlement,
    SettlementTransactions,
    MobileView,
    Modal: () => import("@/UI/Modal"),
    Select,
    Button,
    SingleSettlement,
    Search,
    DatePicker,
  },
  data: () => ({
    showSettlement: false,
    showTransactions: false,
    settlementDetails: {},
    loading: true,
    modelConfig: {
      type: "string",
      mask: "iso",
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    },
    range: {
      start: "",
      end: "",
    },
    masks: {
      input: "DD MMM YY",
    },
    params: {
      settlementId: "",
      page: 0,
      pageSize: 20,
      createdAtFrom: "",
      createdAtTo: "",
      status: "",
    },
    statusOptions: [
      {
        label: "All",
        value: "",
      },
      {
        label: "Pending",
        value: "PENDING",
      },
      {
        label: "Successful",
        value: "SUCCESS",
      },
    ],
    paginateLoading: false,
    handleMore: false,
  }),
  computed: {
    ...mapState({
      merchantOutletId: (state) => state?.auth?.user?.merchantOutlet?.id,
      getSettlements: (state) => state?.settlement?.settlements,
      liveMode: (state) => state?.auth?.liveMode,
    }),
  },
  created() {
    this.getMerchantSettlements();
  },
  watch: {
    liveMode() {
      this.getMerchantSettlements();
    }
  },
  methods: {
    ...mapActions("settlement", ["getAllSettlements"]),

    handleSingleSettlement(value) {
      this.settlementDetails = value;
      this.showSettlement = true;
    },
    getMerchantSettlements(type) {
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.loading = true);
      const id = this.merchantOutletId;
      this.getAllSettlements({
        id,
        type,
        params: this.params,
        liveMode: this.liveMode,
      })
        .then((res) => {
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        });
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getMerchantSettlements("paginate");
    },
    handleSearch() {
      this.params.page = 0;
      this.params.createdAtFrom = this.range.start;
      this.params.createdAtTo = this.range.end;
      this.getMerchantSettlements();
    },
    close() {
      this.params.page = 0;
      this.showSettlement = false;
      this.getMerchantSettlements();
    },
    showTransactionsBatch(id) {
      this.showSettlement = false;
      this.showTransactions = true;
    },
    goBack() {
      this.showSettlement = true;
      this.showTransactions = false;
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/table.css";
.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  width: 12rem;
}
.body-container {
  display: grid;
  grid-template-columns: 100%;
}
.status-container {
  border: 5px;
  background-color: #f5f5f5;
}
@media (max-width: 768px) {
  .body-container {
    grid-template-columns: 100%;
  }
}
</style>
