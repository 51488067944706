<template>
  <div>
    <div class="settlement scrollbar">
      <div>
        <div>
          <div></div>
          <div class="order-time mt-8">
            <p class="text-sm text-black mb-1">Transaction status</p>
            <h3 class="text-black text-lg font-semibold">
              <span
                :class="{
                  pending: settlementDetails.status === 'PENDING',
                  success: settlementDetails.status === 'SUCCESS',
                }"
                >{{ settlementDetails.status | capitalize }}</span
              >
            </h3>
          </div>
          <div class="order-time mt-5">
            <p class="text-sm text-black mb-1">Settlement range</p>
            <h3 class="text-black text-lg font-semibold">
              {{ dayjs(settlementDetails.settlementFromTime).format("D MMM YYYY") }} -
              {{ dayjs(settlementDetails.settlementToTime).format("D MMM YYYY") }}
            </h3>
          </div>
          <div class="payment-method mt-5">
            <p class="text-sm text-black mb-1">Total sales amount</p>
            <h3 class="text-black text-lg font-semibold">
              {{ settlementDetails.totalSalesAmount | formatMoney }}
            </h3>
          </div>
          <div class="payment-method mt-5">
            <p class="text-sm text-black mb-1">Total settlement amount</p>
            <h3 class="text-black text-lg font-semibold">
              {{ settlementDetails.amountSettled | formatMoney }}
            </h3>
          </div>
          <div class="payment-method mt-5">
            <p class="text-sm text-black mb-1">Total charge amount</p>
            <h3 class="text-black text-lg font-semibold">
              {{ settlementDetails.totalCharge | formatMoney }}
            </h3>
          </div>
          <div class="payment-method mt-5">
            <p class="text-sm text-black mb-1">Settlement date</p>
            <h3 class="text-black text-lg font-semibold">
              {{ dayjs(settlementDetails.completedAt).format("D MMM YYYY") }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import Hr from "@/UI/Hr";
export default {
  components: {
    Hr,
  },
  props: {
    settlementDetails: {
      type: Object,
      default: () => {},
      required: true,
    },
    params: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  computed: {
    dayjs() {
      return dayjs;
    },
  },
};
</script>

<style scoped>
.settlement {
  min-height: 400px;
}
.settlement-link {
  color: #727272;
}
</style>
