<template>
  <Settlements />
</template>
<script>
  import Settlements from "@/components/Settlements";
  export default {
    components: {
      Settlements,
    },
  };
</script>
