<template>
  <div class="text-center mt-4" v-if="handleMore">
    <Spinner v-if="loading" />
    <div class="flex justify-center items-center" v-else>
      <div class="flex items-center cursor-pointer" @click="$emit('paginate')">
        <p class="mr-2 font-semibold text-sm md:text-base">View more</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="11"
          fill="none"
        >
          <path
            d="m1.055.618 5.539 4.914-5.466 4.85"
            stroke="#000"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from "@/UI/Spinner";
  export default {
    components: {
      Spinner,
    },
    props: {
      handleMore: {
        type: Boolean,
        default: false,
        required: false,
      },
      loading: {
        type: Boolean,
        deafult: false,
        required: false,
      },
    },
  };
</script>
