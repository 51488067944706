<template>
  <div class="settlement-container mt-4">
    <div class="w-full">
      <transition name="fade" mode="out-in">
        <div>
          <EmptyState
            class="empty mt-16"
            description="No settlement to display"
            v-if="!loading && !getSettlements.length"
          />
          <table
            
            id="template-table"
            class="w-full table-auto"
          >
            <thead v-if="getSettlements.length">
              <tr>
                <th>Payout date</th>
                <th>Total sales</th>
                <th>Total charge</th>
                <th>Total payout</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <Loader v-if="loading" />

            <tbody v-else>
              <tr v-for="settlement in getSettlements" :key="settlement.id">
                <td>
                  {{
                    dayjs(settlement.createdAt).format("D MMM YYYY  |  hh.mm a")
                  }}
                </td>
                <td>{{ settlement.totalSalesAmount | formatMoney }}</td>
                <td>{{ settlement.totalCharge | formatMoney }}</td>
                <td>{{ settlement.amountSettled | formatMoney }}</td>
                <td>
                  <span
                    :class="{
                      pending: settlement.status === 'PENDING',
                      success: settlement.status === 'SUCCESS',
                    }"
                    >{{ settlement.status | capitalize }}</span
                  >
                </td>
                <td>
                  <Button
                    text="View"
                    background="bg-lightPurple"
                    color="text-brandPurple"
                    class="mt-1"
                    p="py-1.5 px-6"
                    fontSize="text-xs"
                    radius="rounded-2xl"
                    fontWeight="font-semibold"
                    @click="$emit('singleSettlement', settlement)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </transition>
      <Pagination
        :handleMore="handleMore"
        :loading="paginateLoading"
        @paginate="$emit('paginate')"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Spinner from "@/UI/Spinner";
import Pagination from "@/UI/Pagination";
import Button from "@/UI/Button";
export default {
  components: {
    EmptyState: () => import("@/UI/EmptyState"),
    Loader: () => import("./Loader"),
    Spinner,
    Pagination,
    Button,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    getSettlements: {
      type: Array,
      default: () => [],
      required: true,
    },
    paginateLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    handleMore: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    dayjs() {
      return dayjs;
    },
  },
};
</script>

<style scoped>
@import "~@/assets/styles/table.css";
@import "~@/assets/styles/animation.css";
.settlement-container {
  min-height: calc(100vh - 15rem);
}
</style>
