<template>
  <div class="settlement scrollbar">
    <transition name="fade" mode="out-in">
      <div v-if="loading" class="mt-4">
        <div v-for="i in 5" :key="i">
          <div class="payment-table">
            <div class="flex items-center">
              <div class="w-full">
                <Skeleton width="50%" height="1.2rem" />
                <Skeleton width="80%" height="1.2rem" />
              </div>
            </div>
            <div class="flex justify-end align-center">
              <div class="w-full flex flex-col items-end">
                <Skeleton width="6rem" height="1.2rem" />
                <Skeleton width="10rem" height="1.2rem" />
              </div>
            </div>
          </div>
          <hr class="bg-lightPurple my-2 border-lightPurple" />
        </div>
      </div>
      <div v-else>
        <div
          v-for="transaction in settlement"
          :key="transaction.id"
          class="mt-4"
        >
          <div class="grid grid-cols-2 content-center">
            <div class="items-center">
              <h3 class="font-semibold text-lg">
                {{ transaction.title | capitalize }}
              </h3>
              <p class="text-sm pt-2">
                {{
                  dayjs(transaction.completedAt).format("MMM D YYYY | hh:mmA")
                }}
              </p>
            </div>
            <div class="flex flex-col items-end">
              <p class="font-semibold">
                {{ transaction.amountOfOrder | formatMoney }}
              </p>
              <p class="text-sm pt-2 text-success">
                {{ transaction.status | capitalize }}
              </p>
            </div>
          </div>
          <Hr class="my-2" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { GET_SETTLEMENT_TRANSACTIONS } from "@/utils/api/settlements.js";
import { mapState } from "vuex";
import Hr from "@/UI/Hr";
import dayjs from "dayjs";
export default {
  props: ["settlementId"],
  components: {
    Hr
  },
  data: () => ({
    loading: true,
    settlement: {}
  }),
  computed: {
    dayjs() {
      return dayjs;
    },
    ...mapState({
      merchantOutletId: state => state?.auth?.user?.merchantOutlet?.id,
      liveMode: state => state?.auth?.liveMode
    })
  },
  beforeMount() {
    this.getTransactions();
  },
  methods: {
    getTransactions() {
      GET_SETTLEMENT_TRANSACTIONS({
        id: this.merchantOutletId,
        settlementId: this.settlementId,
        liveMode: this.liveMode
      })
        .then(res => {
          this.settlement = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.settlement {
  min-height: 400px;
}
.searchContainer {
  border: 1px solid rgba(138, 116, 192, 0.1) !important;
  width: 12rem;
}
.payment-table {
  display: grid;
  grid-template-columns: 70% 1fr;
  grid-gap: 5px;
}
.status-container {
  border: 5px;
  background-color: #f5f5f5;
}
</style>
