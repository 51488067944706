<template>
  <Card :p="p" class="flex searchContainer items-center">
    <img
      class="h-4 w-4 mr-4"
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1623838508/customer/Union_1_x261eq.svg"
      alt="search"
    />
    <div class="line mr-4 h-4"></div>
    <input
      class="border-none outline-none w-full"
      type="text"
      @input="$emit('input', $event.target.value)"
      :value="value"
      :placeholder="placeholder"
    />
  </Card>
</template>
<script>
  export default {
    props: {
      placeholder: {
        type: String,
        default: "Search",
      },
      width: {
        type: String,
        default: "w-auto",
      },
      value: {
        type: String,
        default: "",
      },
      p: {
        type: String,
        default: "px-3 py-1.5",
      },
    },
  };
</script>
<style scoped>
  .searchContainer {
    border: 1px solid #f3f1f9;
  }
  .line {
    /* height: 1.2rem; */
    width: 1px;
    background-color: #f3f1f9;
  }
</style>
